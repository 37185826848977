import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { Dialog, DialogContent, TextField, Button } from "@mui/material";
import { OrderContext } from "../../context/OrderContext";
import Swal from 'sweetalert2';
import { VendorContext } from "../../context/vedorContext";

const Activate = () => {
  const { isAuthenticated, state } = useContext(AuthContext);
  const [text, setText] = useState('');
  const [code, setCode] = useState('');
  const [open, setOpen] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showRefer, setShowRefer] = useState(false);

  const [referralCode, setReferralCode] = useState('');
  const [referApplyText, setReferApplyText] = useState("Apply")
  const [referCode, setReferCode] = useState(false); // State for referral code input visibility
  const location = useLocation();
  const navigate = useNavigate();
  const { vtCodeData, qrCodeData } = useContext(OrderContext);
  const { Orders, user } = state;

  const [loading, setIsLoading] = useState(true);
  const [vehicleNo, setVehicleNo] = useState('');

  const { vendorData, setVendorData } = useContext(VendorContext);

  const handleChange = (event) => {
    const value = event.target.value.toUpperCase();
    if (value.length <= 10) {
      setVehicleNo(value);
    }
  };

  const baseUrl = process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_LOCALAPI
    : process.env.REACT_APP_BACKEND_LIVEAPI;

  const uid = localStorage.getItem("uid");
  console.log("uid in activate", uid)
  useEffect(() => {
    if (!isAuthenticated) {
      const redirectTo = '/activation';
      navigate(`/login?redirect=${encodeURIComponent(redirectTo)}`);
    }
  }, [isAuthenticated, navigate, location]);

  useEffect(() => {
    if (Orders && qrCodeData && user) {
      setIsLoading(false);

      const isOrderExists = qrCodeData?.find((qrcode) =>
        Orders?.some((order) => order.orderId === qrcode.orderId && qrcode.qrStatus === false && qrcode.qrOrderStatus === true)
      );
      // console.log("isOrderExists", Orders);


      const hasSuccessfulPayment = isOrderExists && Orders?.some(
        (order) => order.orderId === isOrderExists.orderId && order.orderStatus !== 'Pending'
      );

      if (isOrderExists && hasSuccessfulPayment) {
        setOrderId(isOrderExists.orderId);
      } else if (isOrderExists) {
        Swal.fire({
          title: 'Payment Pending',
          text: 'The payment status is still pending. Please check back later.',
          icon: 'warning',
          confirmButtonText: 'OK',
          confirmButtonColor: '#808080'
        });
      } else {
        console.log("Order does not exist");
      }
    }
  }, [Orders, qrCodeData, user]);



  useEffect(() => {
    if (referralCode || vendorData) {
      const isMatch = vendorData?.some(vendor => vendor.referralCode.toLowerCase() === referralCode.toLowerCase());

      if (isMatch) {
        setIsValid(true);
        setReferApplyText('Applied');
        setText('Applied Successfully');
        setTimeout(() => {
          setShowRefer(false)
        }, 3000);
      } else {
        setIsValid(false);
        setReferApplyText('Apply');
        setText('Invalid Code');
      }

      const timer = setTimeout(() => {
        setText('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [referralCode, vendorData]);

  if (!vtCodeData) {
    return
  }

  const handleVtCode = async () => {
    const vtCode = vtCodeData?.vtcode
    if (code === vtCode && orderId) {
      // console.log("order --- id", orderId)
      await handleServerUpdate(orderId);

    }
    //vtCodeData?.vtcode now using same uid
    else if (code.toLowerCase() === uid.toLowerCase()) {
      setIsValid(true);
      setShowDetails(true);
      setText('VTCODE Verified. Please enter your details.');

      // Success alert with SweetAlert2
      await Swal.fire({
        title: 'Success',
        text: 'VTCODE Verified. Please enter your details.',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
      setShowRefer(true)

    } else {
      setIsValid(false);
      setText('Please Enter Valid Code');
      await Swal.fire({
        title: 'Invalid',
        text: 'Please Enter Valid Code',
        icon: 'error',
        confirmButtonColor: '#d33',
        confirmButtonText: 'Ok'
      });
      setText('');
    }
  };

  const handleServerUpdate = async (orderId) => {
    try {
      const response = await axios.put(`${baseUrl}/qrstatus`, {
        orderId: orderId,
        uid: uid,
        qrStatus: true,
      });

      if (response.status === 200) {
        Swal.fire({
          title: 'Activated Successfully',
          text: 'Redirecting...',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        }).then(() => {
          navigate(`/profile/${uid}`, { replace: true });
          window.location.reload();
        });
        console.log("status updated");
      } else {
        Swal.fire({
          title: 'Failed to Activate Try Again',
          text: 'Please try again.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error("Error updating QR status:", error);
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.message || "Something went wrong.",
        icon: 'error',
      });
    }
  };

  const generateOrderId = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(5, 10).replace(/-/g, '');
    const randomDigits = Math.floor(1000 + Math.random() * 9000);
    const orderId = formattedDate + String(randomDigits).padStart(4, '0');
    return orderId;
  };

  const getuserId = user?._id;
  // console.log("userdata", getuserId);

  const handleSubmitDetails = () => {
    const fullName = document.querySelector("input[name='fullName']").value.trim();
    const email = document.querySelector("input[name='email']").value.trim();
    const phone = document.querySelector("input[name='phone']").value.trim();
    const phonef1 = document.querySelector("input[name='phonef1']").value.trim();
    const phonef2 = document.querySelector("input[name='phonef2']").value.trim();

    const vehicleType = document.querySelector("select[name='vehicleType']").value;
    const vehicleNo = document.querySelector("input[name='vehicleNo']").value.trim();

    if (!fullName) {
      Swal.fire({
        text: 'Please enter your full name.',
        icon: 'error',
      });
      return;
    }
    if (!email) {
      Swal.fire({
        text: 'Please enter your email.',
        icon: 'error',
      });
      return;
    }
    if (!phone) {
      Swal.fire({
        text: 'Please enter your phone number.',
        icon: 'error',
      });
      return;
    }
    if (!vehicleType) {
      Swal.fire({
        text: 'Please select your vehicle type.',
        icon: 'error',
      });
      return;
    }
    if (!vehicleNo) {
      Swal.fire({
        text: 'Please enter your vehicle number.',
        icon: 'error',
      });
      return;
    }

    // Determine the prefix based on the vehicle type
    let prefix = '';
    if (vehicleType === 'car') {
      prefix = 'C';
    } else if (vehicleType === 'bike') {
      prefix = 'B';
    }
    const prefixedVehicleNo = `${prefix}-${vehicleNo}`;

    const formData = {
      fullName,
      email,
      phone,
      phonef1,
      phonef2,
      vehicleNo: prefixedVehicleNo,
      orderId: generateOrderId(),
      userId: getuserId,
      paymentStatus: 'Success',
      orderStatus: 'Order Received',
      qrStatus: true,
      uid: uid
    };

    if (referralCode) {
      formData.referCode = referralCode;
    }

    console.log("formdata", formData);
    handlePlaceOrderwithAct(formData);
  };

  const handlePlaceOrderwithAct = async (orderData) => {
    try {
      const response = await axios.post(`${baseUrl}/place-order`, orderData);

      if (response.status === 201) {
        Swal.fire({
          title: 'Activated Successfully',
          text: 'Redirecting...',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        }).then(() => {
          navigate(`/profile/${uid}`, { replace: true });
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: 'Failed to Place Order',
          text: 'Please try again.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error("Error placing order:", error);
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.message || "Something went wrong.",
        icon: 'error',
      });
    }
  };

  return (
    <div style={{ height: "150vh", position: 'relative' }}>
      <Dialog
        open={open}
        onClose={() => setOpen(true)}
        aria-labelledby="activation-dialog-title"
        aria-describedby="activation-dialog-description"
        style={{ zIndex: 1, marginTop: "100px" }} // Ensure the dialog has a lower z-index than SweetAlert2
      >
        <DialogContent>
          {!showDetails ? (
            <div className="">
              <TextField
                value={code}
                onChange={(e) => setCode(e.target.value)}
                label="Enter VTCODE"
                variant="outlined"
                fullWidth
                margin="normal"
                autoFocus
              />

              <Button
                onClick={handleVtCode}
                style={{ backgroundColor: "#ffa500", color: "#ffffff" }}
                className='px-3 rounded-sm py-1 my-1'
              >
                Verify Code
              </Button>
            </div>
          ) : (
            <>

              {showRefer &&
                <div className='flex flex-col my-2'>
                  <span className='flex justify-between text-sm font-bold mb-1 tracking-normal'>
                    <button onClick={() => setReferCode(!referCode)} className='pr-14 text-logoClr'>Have a referral code?</button>
                  </span>
                  <>
                    <div className='flex justify-between'>
                      <input
                        name='code'
                        required
                        onChange={(e) => setReferralCode(e.target.value)}
                        maxLength={7}
                        className='w-96 border mr-2 rounded-sm my-1 border-[#d1d1d1] p-2 outline-none'
                        type="text"
                        placeholder='Enter a referral code'
                      />
                      <button
                        style={{ backgroundColor: "#ffa500", color: "#ffffff" }}
                        className='px-3 rounded-sm py-1 my-1 ml-2'
                      >
                        {referApplyText}
                      </button>
                    </div>
                    <p className={`text-xs tracking-wide ${isValid ? 'text-green' : 'text-red'}`}>
                      {text}
                    </p>
                    <div className='flex justify-between items-center mt-2'>
                      <p className='text-sm text-gray-500'>
                        Don't have a referral code?
                      </p>
                      <button
                        onClick={() => {
                          setShowRefer(false);
                        }}
                        className='text-sm text-logoClr underline'
                      >
                        Skip
                      </button>
                    </div>
                  </>
                </div>

              }
              {!showRefer &&
                <>
                  <TextField
                    name="fullName"
                    label="Full Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#f68712'
                        }
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#f68712'
                      }
                    }}
                  />

                  <div className="flex justify-between gap-4">
                    <TextField
                      name="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#f68712'
                          }
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#f68712'
                        }
                      }}
                    />
                    <TextField
                      name="phone"
                      type='tel'
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      inputProps={{
                        maxLength: 10,
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#f68712'
                          }
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#f68712'
                        }
                      }}
                    />
                  </div>

                  <div className="flex justify-between items-center gap-4">
                    <div className="flex-1">
                      <TextField
                        name="phonef1"
                        type="tel"
                        label="Nominee Contact"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        inputProps={{
                          maxLength: 10,
                        }}
                        helperText="(Optional)"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#f68712'
                            }
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#f68712'
                          }
                        }}
                      />
                    </div>
                    <div className="flex-1">
                      <TextField
                        name="phonef2"
                        type="tel"
                        label="Emergency Contact"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        inputProps={{
                          maxLength: 10,
                        }}
                        helperText="(Optional)"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#f68712'
                            }
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#f68712'
                          }
                        }}
                      />
                    </div>
                  </div>

                  <TextField
                    name="vehicleNo"
                    label="Vehicle Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                    value={vehicleNo}
                    inputProps={{
                      maxLength: 10,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#f68712'
                        }
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#f68712'
                      }
                    }}
                  />

                  <TextField
                    name="vehicleType"
                    label="Vehicle Type"
                    variant="outlined"
                    select
                    fullWidth
                    margin="normal"
                    SelectProps={{
                      native: true,
                      sx: {
                        '& option': {
                          backgroundColor: '#f68712 !important',
                          color: 'white'
                        },
                        '& option:hover': {
                          backgroundColor: '#f68712 !important',
                          color: 'white'
                        },
                        '& option:checked': {
                          backgroundColor: '#f68712 !important',
                          color: 'white'
                        },
                        '& select:focus > option:checked': {
                          backgroundColor: '#f68712 !important',
                          color: 'white'
                        }
                      }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#f68712'
                        },
                        '& .MuiSelect-select': {
                          backgroundColor: props => props.value ? '#f68712' : 'transparent',
                          color: props => props.value ? 'white' : 'inherit'
                        }
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#f68712'
                      }
                    }}
                  >
                    <option value="" disabled>Select Vehicle Type</option>
                    <option
                      className="p-2 text-white"
                      value="car"
                      style={{
                        backgroundColor: '#f68712',
                        color: 'white'
                      }}
                    >
                      Car
                    </option>
                    <option
                      className="p-2 text-white"
                      value="bike"
                      style={{
                        backgroundColor: '#f68712',
                        color: 'white'
                      }}
                    >
                      Bike
                    </option>
                  </TextField>

                  <Button
                    onClick={handleSubmitDetails}
                    style={{ backgroundColor: "#f68712", color: "#ffffff" }}
                    className='px-3 rounded-sm py-1 my-1 hover:bg-opacity-90'
                  >
                    Submit
                  </Button>
                </>
              }
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Activate;
